.swiper {
  background: #eee;
  height: fit-content;
}

.swiper-wrapper {
  align-items: center;
}
.swiper-slide img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}