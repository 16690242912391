.cluster-marker {
  color: #fff;
  background: #4571d1;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.point-marker {
  color: #fff;
  background: #c84348;
  border-radius: 75%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mapboxgl-popup-close-button {
  font-size: 2rem;
  color: #fff;
  opacity: 0.54;
  z-index: 2;
  transition: opacity 0.3s linear;
}
.mapboxgl-popup-close-button:hover {
  opacity: 0.8;
}

.mapboxgl-popup-content {
  padding: 0 !important;
}

.mapboxgl-popup-tip {
  border-bottom-color: rgba(0, 0, 0, 0.5) !important;
  border-top-color: rgba(0, 0, 0, 0.5) !important;
}

.swiper {
  z-index: unset !important;
}
.swiper-pagination {
  text-align: right !important;
  left: unset !important;
  right: 8px;
}